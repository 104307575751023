<template>
  <!--Fourth Section-->
  <section class="FourthSection mt-n1 pt-md-16 pa-8" style="background-color: #0b2449">
    <div class="d-flex justify-center">

      <v-img src="/images/LandingPage/Diggaj Coder (1).png" width="243px" height="54px"></v-img>
    </div>
    <v-row wrap class="mt-2 mx-md-10">
      <!--1st box-->
      <v-col cols="12" md="4" class="mt-md-10 mb-10" v-for="(stage, index) in internshipStages" :key="index">
        <v-card flat class="mx-n5 mx-md-0" style="background-color: #001738" rounded="lg" height="100%">
          <v-img :src="stage.courseImg" class="ma-5"></v-img>
          <v-card-title class="text-wrap text-center text-md-start mt-6 ml-md-4 landingPage-3-Title">
            {{ stage.title }}
          </v-card-title>

          <ul class="FourthSection-p px-md-16 px-10 mt-n2">
            <li v-for="(higlight, indx) in stage.highlights" :key="indx" class="my-5">
              {{ higlight }}
            </li>
          </ul>
          <v-card-text class="FourthSection-Learn ma-2 d-flex justify-center">
            Learn More
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { useMainStore } from '../stores/index'
export default {
  data() {
    return {
      store: useMainStore(),
      internshipStages: [
        {
          title: '1st Stage Free Internship',
          courseImg: '/images/LandingPage/Rounded Rectangle (3).png',
          highlights: ['Orientation and tranning sessions.', 'Shadowing team members and  participating in meeting', 'Engaging in small-scale projects or tasks to learn basic skills.']
        },
        {
          title: '2nd Stage Work Review',
          courseImg: '/images/LandingPage/Rounded Rectangle (4).png',
          highlights: ['Interns work on more significant projects with clear deliverables.', 'Regular performance reviews and feedback sessions.', 'Opportunities for interns to showcase their work to the team.']
        },
        {
          title: '3rd Stage Paid Job or Internship',
          courseImg: '/images/LandingPage/Rounded Rectangle (5).png',
          highlights: ['Taking on full project responsibilities.', 'Continued learning and professional developement.', 'Integration into the team as a contributing member.']
        }
      ]
    }
  },

}
</script>
<style scoped>
.landingPage-3-Title {
  color: #4DFAFF;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;

}

.FourthSection-p {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;

}

.FourthSection-Learn {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #2E81F7;
}

@media (max-width: 599.99px) {
  .landingPage-3-Title {
    font-size: 16px;
    line-height: 22px;
  }

  .FourthSection-p {
    font-size: 13px;

  }

  .FourthSection-Learn {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #2E81F7;
  }
}
</style>