<template>
  <v-container fluid class="ma-0 pa-0">
    <v-carousel :continuous="true" cycle :show-arrows="false" hide-delimiter-background height="550">
      <v-carousel-item class="gopal" src="/images/LandingPage/Designer-5_1712422325328 2.png" cover>
        <v-container fluid>
          <v-row class="pa-0 pa-md-16 mt-9 mt-md-0 ml-md-5">
            <!-- Text and Button section -->
            <v-col cols="12" md="7" class="">
              <div class="text-section d-flex flex-column align-center align-md-start justify-center">
                <h2 class="landingpage1-title" style="margin-bottom: 20px">
                  Empower Your Future Master
                  FullStack Development Today
                </h2>


                <p class="Landing-para text-center text-md-start">
                  Join Now – Only ₹3,000 Monthly! Land Your Dream Job, Pay 90% Later – No ISA Required
                </p>
                <v-btn v-if="!loggedIn" @click="onJoinfreeClick" rounded="lg" size="large"
                  style="background-color: aqua; color: #14293c"
                  class="align-self-center align-self-md-start mt-5 mt-md-16 text-none">Join For Free</v-btn>
              </div>
            </v-col>

            <!-- Image section -->
            <v-col cols="12" md="5" class="d-flex align-center">
              <div class="image-section flex-grow-1 mt-md-n10">
                <v-img src="/images/LandingPage/Designer-5 1.png" contain></v-img>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
      <v-carousel-item class="gopal" src="public/images/LandingPage/Designer-5_1712422325328 2 (2).png" cover>
        <v-container fluid>
          <v-row class="pa-0 pa-sm-16">
            <!-- Text and Button section -->
            <v-col cols="12" md="7" class="">
              <div class="text-section d-flex flex-column align-center align-md-start justify-center">
                <h2 class="landingpage1-title">TECHNOLOGIES COVERED</h2>
                <!-- <p class="Landing-para mt-md-7">
                  <b> Front-End :</b> React JS , Vuejs , - <b>Intermediate :</b> JPA with
                  Hibernate,HTML 5, CSS 3 (Bootstape), JavaScript
                </p>
                <p class="Landing-para">
                  <b>Back-end :</b> Core Java, DevOps, Angular,Database, and SQL
                </p>

 -->
              <p class="Landing-para mt-md-7">
                  <b> Front-End :</b> HTML, CSS, JavaScript, Bootstrap, jQuery, React.js, Vue.js, GraphQL, JavaScript design patterns, system design basics, Ant Design , Vuetify , Git , Tailwind  <br />
                </p>
              <p class="Landing-para">
                  <b>Back-end :</b> Node.js, MongoDB, and SQL,  DevOps , Javascript ,  Docker , Kubernetes , LLM , Worker threads, AI Integration, vector databases, and system design , Scoket.IO , kafka
                </p>


                <v-btn v-if="!loggedIn" @click="onJoinfreeClick" rounded="lg" size="large"
                  style="background-color: aqua; color: #14293c"
                  class="align-self-center align-self-md-start mt-5 mt-md-16 text-none">Join For Free</v-btn>
              </div>
            </v-col>

            <!-- Image section -->
            <v-col cols="12" md="5" class="d-flex align-center">
              <div class="image-section-second flex-grow-1">
                <v-img src="/images/LandingPage/Group 282.png" contain></v-img>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
import { useMainStore } from '../stores/index'
export default {
  data() {
    return {
      store: useMainStore(),
    }
  },
  computed: {
    loggedIn() {
      return this.store.isLoggedIn;
    },
  },
  methods: {
    onJoinfreeClick() {
      this.store.registerDialog = true;
    }
  }
}
</script>

<style scoped>
.landingpage1-title {
  color: #ffffff;
  font-size: 40px;
  font-weight: 900;
  line-height: 60px;
}

.Landing-para  {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 40.5px;
  text-align: left;
}

.Landing-para b {
  font-size: 18px;
    color: white;
  font-weight: 700;
  line-height: 40.5px;
}

.text-section {
  padding: 20px;
}

.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500.32px;
  height: 510.6px;
}

.image-section-second {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 400px;
  height: 400px;
}

@media (max-width: 1279px) {

  /* Mobile view: stack image, text, and button vertically */
  .v-row {
    flex-direction: column-reverse;
  }

  .text-section,
  .image-section,
  .image-section-second {
    text-align: center;
  }

  .text-section {
    padding: 0px;
  }

  .image-section {
    width: 200.41px;
    height: 200.99px;
  }

  .landingpage1-title {
    font-size: 24px;
    line-height: 24px;
  }

  .Landing-para {
    font-size: 14px;
    line-height: 24px;
  }

  .Landing-para b {
    font-size: 14px;
    line-height: 24px;
  }

  .image-section-second {
    width: 200px;
    height: 200px;
  }
}
</style>
