<template>
  <div class="" style="background-color: #0e2c58">
    <v-row wrap class="mt-n8 mt-md-0 px-md-16">
      <v-col cols="12" md="12">
        <v-card flat style="background: transparent" class="text-white pl-md-5">
          <v-row>
            <v-col cols="12" md="5">
              <v-img width="600px" src="/images/LandingPage/Rectangle 50.png" class="jobAsistance-img mt-5"></v-img>
            </v-col>
            <v-col cols="12" md="6" class="mt-md-6">
              <v-card-title class="d-md-flex text-wrap">
                <div style="width: 32px">
                  <v-img src="/images/LandingPage/Vector (1).png" class="" color="sucess"></v-img>
                </div>

                <p class="jobAsistPage-Title ml-md-5 mt-4 mt-md-0">Guaranteed Interviews</p>
              </v-card-title>
              <v-card-text class="d-flex text-caption ml-md-13">
                <div style="width: 14px">
                  <v-img src="/images/LandingPage/Vector (2).png" class=""></v-img>
                </div>
                <p class="ml-2 jobAsistPage-subtitile">
                  Upon Completion of Placement Readiness Test (PRT):
                </p>
              </v-card-text>
              <v-card-text class="ml-md-13 mt-n5">
                <p class="jobAsistPage-text">
                  You'll be eligible for guaranteed interviews with our extensive network of our hiring partners. These interviews are designed to kickstart your career journey with confidence.
                </p>
              </v-card-text>

              <v-card-title class="d-md-flex text-wrap mt-2 mt-md-0">
                <div style="width: 32px">
                  <v-img src="/images/LandingPage/Group 210.png" class="" color="sucess"></v-img>
                </div>

                <p class="ml-md-5 mt-4 mt-md-0 jobAsistPage-Title">
                    Exclusive Access to Job Portal:
                </p>
              </v-card-title>
              <v-card-text class="d-flex text-caption ml-md-13">
                <div style="width: 14px">
                  <v-img src="/images/LandingPage/Vector (2).png" class=""></v-img>
                </div>
                <p class="ml-2 jobAsistPage-subtitile">
                  <!--n5 means negative 5-->
                  Tailored Job Opportunities:
                </p>
              </v-card-text>
              <v-card-text class="ml-md-13 mt-n5">
                <p class="jobAsistPage-text">
                   Access our dedicated job portal after completing 80% of your course. Explore job openings curated specifically for DiggaJcoder learners, ranging from exciting startups to established tech giants.
                </p>
              </v-card-text>
            </v-col></v-row></v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.jobAsistPage-Title {
  font-size: 21px;
  font-weight: 700;
  line-height: 31.5px;
  color: #FFFFFF;

}

.jobAsistPage-subtitile {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #C1FF72;

}

.jobAsistPage-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #FFFFFF;

}

@media (max-width: 599.99px) {
  .jobAsistPage-Title {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;

  }

  .jobAsistPage-subtitile {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #C1FF72;
  }

  .jobAsistPage-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
  }

}
</style>
