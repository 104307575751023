<template>
  <div class="" style="background-color: #0e2c58">
    <v-row wrap class="mt-n8 mt-md-0 px-md-16">
      <v-col cols="12" md="12">
        <v-card flat style="background: transparent" class="text-white pl-md-5">
          <v-row>
            <v-col cols="12" md="5">
              <v-img width="600px" src="/images/LandingPage/Rectangle 50.png" class="jobAsistance-img mt-5"></v-img>
            </v-col>
            <v-col cols="12" md="6" class="mt-md-6">
              <v-card-title class="d-md-flex text-wrap">
                <div style="width: 32px">
                  <v-img src="/images/LandingPage/Group 253.png" class="" color="sucess"></v-img>
                </div>

                <p class="interviewProp-Title ml-md-5 mt-4 mt-md-0">Mock Interview Preparation</p>
              </v-card-title>
              <v-card-text class="d-flex text-caption ml-md-13">
                <div style="width: 14px">
                  <v-img src="/images/LandingPage/Vector (2).png" class=""></v-img>
                </div>
                <p class="ml-2 interviewProp-subtitile">
                  After 80% of the course completion.
                </p>
              </v-card-text>
              <v-card-text class="ml-md-13 mt-n5">
                <p class="interviewProp-text">
                  At DiggaJcoder, we prioritize practical skill development through mock interviews conducted by seasoned technical experts. These sessions are scheduled after 80% of your course completion to simulate real-world interview scenarios. You'll receive invaluable feedback and tailored tips to enhance your interview performance and build confidence.


                </p>
              </v-card-text>

              <v-card-title class="d-md-flex text-wrap mt-2 mt-md-0">
                <div style="width: 32px">
                  <v-img src="/images/LandingPage/Vector (3).png" class="" color="sucess"></v-img>
                </div>

                <p class="interviewProp-Title ml-md-5 mt-4 mt-md-0">1-on-1 Career Mentoring Sessions</p>
              </v-card-title>
              <v-card-text class="d-flex text-caption ml-md-13">
                <div style="width: 14px">
                  <v-img src="/images/LandingPage/Vector (2).png" class=""></v-img>
                </div>
                <p class="ml-2 interviewProp-subtitile">
                  After 80% of the course completion
                </p>
              </v-card-text>
              <v-card-text class="ml-md-13 mt-n5">
                <p class="interviewProp-text">
                  Designed to refine your career trajectory, our one-on-one mentoring sessions commence after 80% of your course. You'll collaborate with dedicated mentors who leverage your educational background, past experiences, and future aspirations to craft a personalized career development plan. These sessions equip you with the skills and mindset necessary to secure your dream job.


                </p>
              </v-card-text>
            </v-col></v-row></v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.interviewProp-Title {
  font-size: 21px;
  font-weight: 700;
  line-height: 31.5px;
  color: #FFFFFF;
}

.interviewProp-subtitile {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #C1FF72;

}

.interviewProp-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #FFFFFF;
}

@media (max-width: 599.99px) {
  .interviewProp-Title {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;

  }

  .interviewProp-subtitile {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #C1FF72;
  }

  .interviewProp-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
  }

}
</style>
