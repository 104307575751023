<template>
  <div class="" style="background-color: #0e2c58">
    <v-row wrap class="mt-n8 mt-md-0 px-md-16">
      <v-col cols="12" md="12">
        <v-card flat style="background: transparent" class="text-white pl-md-5">
          <v-row>
            <v-col cols="12" md="5">
              <v-img width="600px" src="/images/LandingPage/Rectangle 50.png" class="jobAsistance-img mt-5"></v-img>
            </v-col>
            <v-col cols="12" md="6" class="mt-md-6">
              <v-card-title class="d-md-flex text-wrap">
                <div style="width: 32px" class="">
                  <v-img src="/images/LandingPage/Group 209.png" class="" color="sucess"></v-img>
                </div>

                <p class="ProfileBuild-Title ml-md-5 mt-4 mt-md-0">Career Oriented Sessions</p>
              </v-card-title>
              <v-card-text class="d-flex text-caption ml-md-13">
                <div style="width: 14px">
                  <v-img src="/images/LandingPage/Vector (2).png" class=""></v-img>
                </div>
                <p class="ml-2 ProfileBuild-subtitile">

                </p>
              </v-card-text>
              <v-card-text class="ml-md-13 mt-n5">
                <p class="ProfileBuild-text">
                  live interactive sessions led by industry experts at DiggaJcoder. These sessions are designed to equip you with in-demand skills sought after by hiring managers. Each session is carefully curated to ensure you stay focused on your upskilling objectives and gain practical insights to thrive in the industry.
                </p>
              </v-card-text>

              <v-card-title class="d-md-flex text-wrap mt-2 mt-md-0">
                <div style="width: 32px">
                  <v-img src="/images/LandingPage/Group 256.png" class="" color="sucess"></v-img>
                </div>

                <p class="ProfileBuild-Title ml-md-5 mt-4 mt-md-0">
                  After 80% of the course copmletion.
                </p>
              </v-card-title>
              <v-card-text class="d-flex text-caption ml-md-13">
                <div style="width: 14px">
                  <v-img src="/images/LandingPage/Vector (2).png" class=""></v-img>
                </div>
                <p class="ml-2 ProfileBuild-subtitile">
                  After 90% of the course completion
                </p>
              </v-card-text>
              <v-card-text class="ml-md-13 mt-n5">
                <p class="ProfileBuild-text">
                  Receive specialized guidance in crafting a compelling resume and LinkedIn profile from our dedicated career services team. Learn effective strategies to capture the attention of hiring managers during the profile shortlisting stage, enhancing your chances of securing interviews.
                </p>
              </v-card-text>
            </v-col></v-row></v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.ProfileBuild-Title {
  font-size: 21px;
  font-weight: 700;
  line-height: 31.5px;
  color: #FFFFFF;
}

.ProfileBuild-subtitile {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #C1FF72;
}

.ProfileBuild-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #FFFFFF;
}

@media (max-width: 599.99px) {
  .ProfileBuild-Title {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;

  }

  .ProfileBuild-subtitile {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #C1FF72;
  }

  .ProfileBuild-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
  }

}
</style>
