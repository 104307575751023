<template>
  <section class="FifthSection mt-n1 pt-md-16 pa-5" style="background-color: #0e2c58">
    <div class="FifthSection-div py-6">
      <div class="my-md-12 mb-5">
        <h1 class="FifthSection-h1 text-center ">Career Services</h1>
      </div>

      <!-- <div class="FourthSection-Button pt-6"> -->
      <!-- <v-container> -->
      <v-row class="d-flex justify-center mx-2 mx-md-16">
        <v-col cols="12" md="3">
          <v-btn class="sub-button text-white" density="default" block color="#02142B" style="border: 1px solid aqua"
            @click="showContent('content1')">Job Asistance
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn class="sub-button" density="default" color="#02142B" block @click="showContent('content2')">Interview
            Preparation
          </v-btn>
        </v-col>
        <v-col cols="12" md="3"><v-btn class="sub-button" density="default" color="#02142B" block
            @click="showContent('content3')">Profile Building
          </v-btn>
        </v-col>
      </v-row>
      <!-- </v-container> -->
    </div>
    <v-row wrap class="">
      <v-col cols="12" md="12" v-if="currentContent === 'content1'" x>
        <!-- <div> -->
        <SharedLandingPagesJobAsistance class="pl-md-16 pb-10" />
        <!-- </div> -->
      </v-col>
      <v-col cols="12" md="12" v-if="currentContent === 'content2'">
        <!-- <div v-if="currentContent === 'content2'"> -->
        <SharedLandingPagesInterviewPrep class="pl-md-16 pb-10" />
        <!-- </div> -->
      </v-col>
      <v-col cols="12" md="12" v-if="currentContent === 'content3'">
        <!-- <div v-if="currentContent === 'content3'"> -->
        <SharedLandingPagesProfileBuilding class="pl-md-16 pb-10" />
        <!-- </div> -->
      </v-col>
      <!-- </v-cols> -->
    </v-row>
  </section>
</template>
<style scoped>
/*Fifth Section CSS*/
.FifthSection-div {
  /* display: flex;
  justify-content: center; */

  background-color: #0e2c58;
  color: white;
}

.FifthSection-h1 {
  text-align: center;
  font-size: 36px;
  color: #FFFFFF;
  font-weight: 900;
  line-height: 38px;
}

.sub-button1 {
  border: 1px solid #4dfaff;
}

.sub-button:hover {
  background-color: #02142b;
  color: #4dfaff;
  border: 1px solid #4dfaff;
}

@media (max-width: 599.99px) {
  .FifthSection-h1 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 900;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
  }

}
</style>

<script>
export default {
  data() {
    return {
      currentContent: "content1",
    };
  },
  methods: {
    showContent(content) {
      this.currentContent = content;
    },
  },
};
</script>
